import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon, IonItem, IonLabel, IonList } from '@ionic/react';
import { informationCircleOutline, refreshCircleOutline } from 'ionicons/icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import { NormalText, StrongText, Subtitle, Title } from '../../components/common';
import DashboardBar from '../../components/dashboardBar';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { checkBackgroundColor, forwardTo, getSingleDeliveryOption, isDefined, isEmptyObject } from '../../lib/utils';
import card from '../../assets/images/icons/ticket.svg'
import repeat from '../../assets/images/icons/repeat.svg'
import './index.css';

class Dashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			singleDelivery: null,
			image: '',
			dashboardWithTiles: false,
			backgorundColor: false,
		};
	}
	calculateHeaderHeight = () => {
		const orders = this.props.orderHistory.length;
		const vouchers = this.props.vouchers.length;
		let size = 0;
		if (this.props.loggedIn) {
			if (vouchers > 0) {
				size = size + 60;
			}
			if (orders > 0) {
				size = size + 60;
			}
		}

		return size;
	};

	async componentDidMount() {
		const styles = this.props.clientStyles.colors?.['--okx-dashboard-background'];
		if (styles) {
			this.setState({ backgorundColor: styles });
		}
		const { clientProfile } = this.props;
		if (getConfig().general.dashboardWithTiles) {
			this.setState({ dashboardWithTiles: true });
		}
		getSingleDeliveryOption().then((res) => {
			this.setState({
				singleDelivery: res,
				image: clientProfile?.main_image || "",
			});
		});
	}

	render() {
		const { __, screenName, orderHistory, vouchers } = this.props;
		const { singleDelivery, image } = this.state;
		const dashboardContent = getConfig().dashboardConfig;
		const iconColor = getConfig().general.dashboardWithTiles ? (this.state.backgorundColor ? checkBackgroundColor(this.state.backgorundColor) : 'white') : null;

		return (
			<Layout
				color="transparent"
				noPadding={true}
				headerIconColors={iconColor}
			>
				{!this.state.dashboardWithTiles ? (
					<>
						<div className="absolute-content dash-layout" style={{ backgroundImage: `url(${image})` }}></div>
						<IonCard className="dash-card">
							<IonCardHeader className="ion-text-center">
								<IonLabel>{__('Welcome Back')}</IonLabel>
								<Title>{screenName}</Title>
							</IonCardHeader>

							<IonCardContent className="dash-menu">
								<IonList>
									{getConfig().delivery.filter((d) => !d.isRemoved && !d.isDisabled).length > 0 && (
										<>
											{getConfig().flags.hasOrdering ? (
												singleDelivery ? (
													<IonItem className="clickable" onClick={() => forwardTo('/click-and-collect')}>
														<div tabIndex="-1"></div>
														<IonLabel>{__(singleDelivery?.label)}</IonLabel>
													</IonItem>
												) : (
													<IonItem className="clickable" onClick={() => forwardTo('/delivery-options')}>
														<div tabIndex="-1"></div>
														<IonLabel>{__('Start New Order')}</IonLabel>
													</IonItem>
												)
											) : null}
										</>
									)}
									{getConfig().flags.hasLoyalty && (
										<IonItem className="clickable" onClick={() => forwardTo('/loyalty')}>
											<div tabIndex="-1"></div>
											<IonLabel>{__('Loyalty')}</IonLabel>
										</IonItem>
									)}

									<IonItem lines="none" className="clickable" onClick={() => forwardTo('/account')}>
										<div tabIndex="-1"></div>
										<IonLabel>{__('My Account')}</IonLabel>
									</IonItem>
								</IonList>
								{getConfig().general.dashboardCardImage &&
									<div className='dash-card-image-wrapper'>
									<img src={getConfig().general.dashboardCardImage} />
								</div>
								}
							
							</IonCardContent>
						</IonCard>
					</>
				) : (
					<>
						{this.props.loggedIn && (
							<div className="dashboard-bars-wrapper">
								{orderHistory.filter((order) => !order.is_gift && order.status.toUpperCase() !== 'NEW' && order.status.toUpperCase() !== 'CREATED').length > 0 && (
									<DashboardBar handleClick={() => forwardTo('./history', { tab: 'order' })}>
										<IonIcon size="medium" color="primary" icon={refreshCircleOutline}></IonIcon>
										<StrongText>{__('Reorder previous order')}</StrongText>
									</DashboardBar>
								)}
								{vouchers.length > 0 && (
									<DashboardBar
										handleClick={() =>
											forwardTo('/loyalty', {
												openVoucherModal: vouchers[0].id,
											})
										}
									>
										<IonIcon color="primary" size="medium"  icon={card}></IonIcon>
										<StrongText>{`${__('New voucher')}: ${__(vouchers[0].reward.name)}`}</StrongText>
									</DashboardBar>
								)}

							</div>
						)}
						<div className={`dashboard-content-${iconColor} dashboard-content`}>
							<div
								className="dashboard-header"
							>
								<Title>
									{__('Welcome')} {screenName}
								</Title>
								<NormalText>{__(dashboardContent?.description)}</NormalText>
							</div>
							<div className="dashboard-cards-wrapper" style={{ ...(getConfig().frenchDisclaimer?.dashboardDisclaimerImage ? { paddingBottom: '50px' } : null) }}>
								{dashboardContent?.data.map((item, i) => {
									return (
										<div key={i} style={{ backgroundImage: `url(${item.image})` }} className="dashboard-card " onClick={() => forwardTo(item.path)}>
											<Subtitle className='bold' key={i}>{__(item.title)}</Subtitle>
										</div>
									);
								})}
							</div>
						</div>
					</>
				)}
				{getConfig().frenchDisclaimer?.dashboardDisclaimerImage && (
					<div className="dashboard-disclaimer-wrapper">
						<img src={getConfig().frenchDisclaimer?.dashboardDisclaimerImage}></img>
					</div>
				)}
			</Layout>
		);
	}
}

const stateToProps = (state) => {
	const { profile, vouchers, auth } = state.profile;
	const { clientProfile, clientStyles } = state.common;
	const { orderHistory } = state.orders;

	let screenName = '';
	if (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) {
		screenName = profile.first_name;
	}
	return {
		loggedIn: auth.loggedIn,
		screenName,
		clientProfile,
		clientStyles,
		orderHistory: orderHistory || [],
		vouchers: vouchers || [],
	};
};

export default connect(stateToProps)(withTranslation(Dashboard));
