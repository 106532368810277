import { IonAlert, IonButton, IonInput, IonItem, IonTextarea } from '@ionic/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FieldError, NormalText, SmallText, Spacer, StrongText, Subtitle, Title } from '../../components/common';
import Mobiscroll from '../../components/mobiscroll';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';
import { withTranslation } from '../../lib/translate';
import { checkBackgroundColor, forwardTo, validateForm } from '../../lib/utils';
import Layout from '../../components/layout';
import { withRouter } from 'react-router';
import { getConfig } from '../../appConfig';

const { SelectOption } = Mobiscroll;

class GiftVouchers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			giftVoucherValues: [],
			email: '',
			sender_email: this.props.profile.email || '',
			sender_name: this.props.profile.first_name || '',
			logo_image: this.props.clientProfile.logo_image || '',
			logo_image_light: this.props.clientProfile.logo_image_light || this.props.clientProfile.logo_image || '',
			proceedeToOrderSummaryModalOpen: false
		};
		this.voucherValidator = {
			email: { type: 'email', required: true },
			sender_email: { type: 'email', required: true },
		};
	}
	componentDidMount() {
		const styles = this.props.clientStyles.colors?.['--okx-background-color'];
		if (styles) {
			this.setState({ backgroundColor: styles });
		}
		const theme = this.props.clientStyles.theme || 'light';
		if (theme) {
			this.setState({ theme });
		}
		const voucher = this.props.location?.state?.voucher;

		this.setState({
			giftVoucherValues: getConfig().general.giftVouchers,
			giftVoucherAmount: getConfig().general.giftVouchers[1],
		});
		if (voucher) {
			this.setState({
				personal_message: voucher.personal_message,
				email: voucher.recipient_email,
				giftVoucherDate: voucher.send_on_date,
				giftVoucherAmount: voucher.points_value / 100,
				sender_email: voucher.sender_email || this.props.profile.email,
				sender_name: voucher.sender_name,
			});
		}
	}
	proceedeToOrderSummary = () => {
		const { email, sender_email } = this.state;
		const { dispatch, restaurants, profile } = this.props;
		let voucherErrors = validateForm(this.voucherValidator, { email, sender_email });
		this.setState({ voucherErrors });
		if (Object.keys(voucherErrors).length === 0) {
			const data = {
				personal_message: this.state.personal_message,
				recipient_email: this.state.email,
				send_on_date: this.state.giftVoucherDate,
				points_value: this.state.giftVoucherAmount * 100,
				sender_email: this.state.sender_email || this.props.profile.email,
				sender_name: this.state.sender_name,
			};

			Basket.reset();
			const uuid = `${profile.id}_${Date.now()}`;
			Basket.setUUID(uuid);
			Basket.setCollectionTime(null);
			Basket.addToBasket({
				item: { productPrice: this.state.giftVoucherAmount, productName: 'eGift Voucher', giftVoucherData: data },
				quantity: 1,
			});
			Basket.setDeliveryOption({
				id: 'gift-vouchers',
			});
			Basket.setGift(data);

			Basket.setRestaurant(restaurants.find((restaurant) => restaurant.name === 'Vouchers'));
			Basket.setServicePercentage(0);
			dispatch({
				type: 'SET_GIFT_VOUCHER_DATA',
				value: data,
			});
			forwardTo('/gift-voucher', {
				voucher: data,
				hasEdit: true,
			});
		}
	};
	formatVoucherValues = () => {
		const values = this.state.giftVoucherValues;
		const data = values.map((value) => {
			return { text: Basket.formatPrice(value), value };
		});

		return data;
	};
	getDateArray = (obj) => {
		var start = obj.startDate.clone();
		var end = obj.endDate.clone();
		var res = [];
		while (start.isBefore(end)) {
			res.push(start.toDate());
			start.add(1, 'd');
		}
		return res;
	};
	formatDateValues = () => {
		const startDate = moment();
		const endDate = moment().add(30, 'days');
		const daysInBetween = this.getDateArray({ startDate, endDate });
		const data = daysInBetween.map((date) => {
			return {
				text: moment(date).format('DD MMMM YYYY'),
				value: moment(date).format('MM/DD/YYYY'),
			};
		});
		return data;
	};
	updateGiftVoucher = () => {
		const { email, sender_email } = this.state;
		let voucherErrors = validateForm(this.voucherValidator, { email, sender_email });
		this.setState({ voucherErrors });
		if (Object.keys(voucherErrors).length === 0) {
			const data = {
				gift_id: this.props.location?.state?.voucher?.id,
				personal_message: this.state.personal_message,
				recipient_email: this.state.email,
				send_on_date: this.state.giftVoucherDate,
			};
			this.props.dispatch({
				type: 'UPDATE_GIFT_VOUCHER',
				data,
			});
		}
	};
	render() {
		const { __ } = this.props;
		const dateValues = this.formatDateValues();
		const isSent = this.props.location?.state?.isSent ? true : false;
		const voucher = this.props.location?.state?.voucher;
		const title = isSent ? 'Update gift voucher' : voucher ? 'Edit gift voucher' : 'Send gift voucher';

		let logoColor = '';
		if (this.state.theme !== 'custom') {
			logoColor = this.state.theme == 'dark' ? 'white' : 'dark';
		} else {
			logoColor = this.state.backgroundColor ? checkBackgroundColor(this.state.backgroundColor) : 'dark';
		}
		return (
			<Layout headerWithTitle title={__(title)} hideSecondToolbar={true} color="transparent" scrollY={false} backHandler={this.backHandler}>
				<div className="absolute-content scrollable-y">
					<img className="block" style={{ margin: 'auto', marginBottom: '20px', width: '100px' }} src={logoColor === 'white' ? this.state.logo_image_light : this.state.logo_image} />

					<Title className="web-only block">{__(title)}</Title>
					{this.props.clientProfile.gift_voucher_description && <NormalText>{__(this.props.clientProfile.gift_voucher_description)}</NormalText>}
					<div className="input-field-container">
						<NormalText>{__('How much do you want to send?')}</NormalText>
						<IonItem disabled={isSent} lines="none" className="input-field-wrapper dropdown-field">
							<SelectOption
								disabled={isSent}
								cssClass="language-picker"
								display="center"
								onSet={(e, a) =>
									this.setState({
										giftVoucherAmount: a.getVal(),
									})
								}
								data={this.formatVoucherValues()}
								label="Location"
								value={this.state.giftVoucherAmount}
								cancelText={__('Cancel')}
								setText={__('OK')}
							/>
						</IonItem>
					</div>
					<div className="input-field-container">
						<NormalText>{__('Where shall we send the gift voucher?')} *</NormalText>

						<IonItem lines="none" className="input-field-wrapper">
							<IonInput
								onIonInput={(e) => this.setState({ email: e.target.value })}
								type="email"
								pattern="email"
								inputmode="email"
								value={this.state.email}
								placeholder={__('Recipient email address')}
							></IonInput>
						</IonItem>
						{this.state.voucherErrors?.email && <FieldError className="field-error" value={__(this.state.voucherErrors.email)} />}
					</div>
					<div className="input-field-container">
						<NormalText>{__('Enter a personal message')}</NormalText>

						<IonItem lines="none" className="input-field-wrapper">
							<IonTextarea
								rows={5}
								placeholder={__('Your message')}
								onIonChange={(e) => this.setState({ personal_message: e.target.value })}
								value={this.state.personal_message}
							></IonTextarea>
						</IonItem>
					</div>
					<div className="input-field-container">
						<NormalText>{__('When shall we send the voucher?')}</NormalText>

						<IonItem lines="none" className="input-field-wrapper  dropdown-field">
							<SelectOption
								display="center"
								onSet={(e, a) =>
									this.setState({
										giftVoucherDate: a.getVal(),
									})
								}
								data={dateValues}
								label="Location"
								value={this.state.giftVoucherDate}
								cancelText={__('Cancel')}
								setText={__('OK')}
								onInit={() => {
									if (!this.state.giftVoucherDate) {
										this.setState({ giftVoucherDate: dateValues[0].value });
									}
								}}
							/>
						</IonItem>
					</div>
					<Spacer size={1} />
					<StrongText className="block">{__('Your details')}</StrongText>
					<Spacer size={1} />
					<div className="input-field-container">
						<NormalText>{__('Your name (sender)')}</NormalText>

						<IonItem lines="none" className="input-field-wrapper">
							<IonInput
								disabled={this.props.auth.loggedIn && this.props.profile.first_name !== ''}
								onIonInput={(e) => this.setState({ sender_name: e.target.value })}
								type="email"
								pattern="email"
								inputmode="email"
								value={this.state.sender_name}
								placeholder={__("Sender's name")}
							></IonInput>
						</IonItem>
					</div>
					<div className="input-field-container">
						<NormalText>{__('Your email address (sender)')} *</NormalText>
						<IonItem lines="none" className="input-field-wrapper">
							<IonInput
								disabled={this.props.auth.loggedIn}
								onIonInput={(e) => this.setState({ sender_email: e.target.value })}
								type="email"
								pattern="email"
								inputmode="email"
								value={this.state.sender_email}
								placeholder={__("Sender's email address")}
							></IonInput>
						</IonItem>
						{this.state.voucherErrors?.sender_email && <FieldError className="field-error" value={__(this.state.voucherErrors.sender_email)} />}
					</div>

					<Spacer size={1} />
					{isSent ? (
						<IonButton disabled={this.state.email.length === 0} onClick={() => this.updateGiftVoucher()} color="primary" expand="block">
							{__('Update gift voucher')}
						</IonButton>
					) : (
						<IonButton
							disabled={this.state.email.length === 0}
							onClick={() => {
								if (voucher) {
									this.proceedeToOrderSummary();
								} else {
									let voucherErrors = validateForm(this.voucherValidator, { email: this.state.email, sender_email: this.state.sender_email });
									this.setState({ voucherErrors });
									if (Object.keys(voucherErrors).length === 0) {
										this.setState({ proceedeToOrderSummaryModalOpen: true });
									}
								}
							}}
							color="primary"
							expand="block"
						>
							{voucher ? __('Edit') : __('Continue')}
						</IonButton>
					)}
				</div>
				<IonAlert
					isOpen={this.state.proceedeToOrderSummaryModalOpen}
					onDidDismiss={() => this.setState({ proceedeToOrderSummaryModalOpen: false })}
					header={__('Confirm your email')}
					message={`${__('Your voucher confirmation will be sent to the following email address:')} ${this.state.sender_email}`}
					buttons={[
						{
							text: __('Cancel'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => this.setState({ proceedeToOrderSummaryModalOpen: false }),
						},
						{
							text: __('OK'),
							handler: () => this.proceedeToOrderSummary(),
						},
					]}
				/>
			</Layout>
		);
	}
}

const stateToProps = (state) => {
	return {
		sentGiftVouchers: state.orders.sentGiftVouchers || [],
		redeemedGiftVoucher: state.orders.redeemedGiftVoucher || null,
		auth: state.profile.auth,
		navConfig: state.common.navConfig,
		clientProfile: state.common.clientProfile,
		clientStyles: state.common.clientStyles,
		profile: state.profile.profile,
		restaurants: state.restaurants.restaurants || [],
	};
};

export default withRouter(withTranslation(connect(stateToProps)(GiftVouchers)));
