import React from 'react';
import { IonIcon } from '@ionic/react';
import { connect } from 'react-redux';
import Modal from '../../components/modal';
import { Title, Spacer, NormalText, Subtitle } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { checkmark, informationCircleOutline } from 'ionicons/icons';
import { getMilestoneRewardsInfo } from '../../store/actions';

class MilestoneRewards extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedReward: null,
			rewardInfoModalOpen: false,
		};
	}
	componentDidMount() {
		this.props.dispatch(getMilestoneRewardsInfo());
	}
	render() {
		const { __, lifetime_balance, rewards, milestoneInfo } = this.props;
		const { rewardInfoModalOpen } = this.state;
		return (
			<>
				<div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => this.setState({ rewardInfoModalOpen: true })}>
					<Subtitle className="loyalty-heading primary-color">{__('Loyalty lifetime rewards')}</Subtitle>
					<IonIcon
						style={{ cursor: 'pointer', height: '25px', width: '25px' }}
						color="primary"
						onClick={() => {
							this.setState({ rewardInfoModalOpen: true });
						}}
						icon={informationCircleOutline}
					/>
				</div>
				<div className="milestone-rewards-wrapper" onClick={() => this.setState({ rewardInfoModalOpen: true })}>
					<NormalText className="block">{`${__('You have')} ${lifetime_balance} ${__('points')}`}</NormalText>
					<Spacer size={1} />
					{rewards.map((reward) => (
						<div className={lifetime_balance >= reward.stamps_required ? 'reward-unlocked' : ''}>
							<IonIcon icon={checkmark} />
							<NormalText>{`${reward.stamps_required} ${__('points')}`}</NormalText>
							<NormalText>{reward.name}</NormalText>
						</div>
					))}
				</div>

				<Modal
					className="loyalty-faq-modal"
					isOpen={rewardInfoModalOpen}
					onDidDismiss={() => {
						this.setState({ rewardInfoModalOpen: false });
					}}
				>
					<div className="lefted">
						<Title>{__('Milestone Rewards')}</Title>
						<div dangerouslySetInnerHTML={{ __html: milestoneInfo }}></div>
					</div>{' '}
				</Modal>
			</>
		);
	}
}
const stateToProps = (state) => {
	const { profile, auth } = state.profile;

	return {
		vouchers: state.profile.vouchers || [],
		qr_code: state.profile.profile.qr_code,
		profile,
		auth,
		milestoneInfo: state.common.milestoneInfo,
	};
};

export default connect(stateToProps)(withTranslation(MilestoneRewards));
