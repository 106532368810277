import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IonButton, IonItem, IonLabel, IonInput, IonList, IonToggle, IonCheckbox } from '@ionic/react';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { FieldError, Title, SmallText, NormalText, Spacer, StrongText } from '../../components/common';
import { validateForm, forwardTo } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { setGuestUser, updateProfile } from '../../store/actions';
import Basket from '../../lib/basket';
import { getConfig } from '../../appConfig';
import MobileInput from '../../components/mobileInput';
import './index.css';

const { getMobile, setMobile } = Basket;

class ContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: this.props.profile.first_name || '',
      last_name: this.props.profile.last_name || '',
      mobile: getMobile() || this.props.profile.mobile || '',
      formErrors: {},
      guest_first_name: this.props.guestUserData.guest_first_name || '',
      guest_email: this.props.guestUserData.guest_email || '',
      guest_accepted_terms_and_conditions:
        this.props.guestUserData.guest_accepted_terms_and_conditions || false,
      guest_mobile: getMobile() || this.props.guestUserData.guest_mobile || '',
      guestFormErrors: {},
      guest_mobile_value: '',
      guest_mobile_code: '',
      mobile_code: this.props.profile.mobile_code ? this.props.profile.mobile_code : Basket.getMobileCode() ? Basket.getMobileCode() : null,
      mobile_value: this.props.profile.mobile_value
        ? this.props.profile.mobile_value : Basket.getMobileValue() ? Basket.getMobileValue()
          : this.props.profile.mobile == this.props.profile.mobile_code
            ? ''
            : this.props.profile.mobile,
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.formConfig = {
      first_name: { type: 'first_name', required: true },
      mobile: { type: 'tel', required: true },
    };


    this.guestValidationConfig = {
      guest_first_name: { type: 'first_name', required: true },
      guest_mobile: { type: 'tel', required: Basket.getOrderType() === 'Table' ? (getConfig().general.tableRequiredFields || []).filter((el) => el === 'mobile').length > 0 : true },
      ...(getConfig().flags.hasMobileCountryCode && {
        guest_mobile_code: { type: 'text', required: Basket.getOrderType() === 'Table' ? (getConfig().general.tableRequiredFields || []).filter((el) => el === 'mobile').length > 0 : true },
      }),
      ...(getConfig().flags.hasMobileCountryCode && {
        guest_mobile_value: { type: 'number', required: Basket.getOrderType() === 'Table' ? (getConfig().general.tableRequiredFields || []).filter((el) => el === 'mobile').length > 0 : true },
      }),
      guest_email: { type: 'email', required: Basket.getOrderType() !== 'Table' },
      guest_accepted_terms_and_conditions: { type: 'toggle', toggle: true },
    };
  }

  componentDidMount() {
    if (this.state.first_name !== '' && this.checkIfMobileExists() && !getConfig().flags.hasYocoPayment) {
      forwardTo('/checkout', { skipContactDetails: true });
    }
  }

  checkIfMobileExists(){
    if(getConfig().flags.hasMobileCountryCode && this.state.mobile_value && this.state.mobile_code && this.state.mobile){
      return true;
    }
    if(!getConfig().flags.hasMobileCountryCode && this.state.mobile){
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.first_name !== prevProps.profile.first_name) {
      this.setState({ first_name: this.props.profile.first_name });
    }
    if (this.props.profile.last_name !== prevProps.profile.last_name) {
      this.setState({ last_name: this.props.profile.last_name });
    }
    if (this.props.profile.mobile !== prevProps.profile.mobile) {
      this.setState({
        mobile: this.props.profile.mobile ? this.props.profile.mobile : getMobile(),
      });
    }
  }

  handleInput = (key, val) => {
    if (key === 'mobile' || key === 'guest_mobile') {
      setMobile(val);
    }
    this.setState({ [key]: val });
  };

  handleSave() {
    const { cards } = this.props;
    let formErrors = validateForm(this.formConfig, this.state);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { first_name, last_name, mobile, mobile_code,
        mobile_value } = this.state;
      const profile = {
        first_name: first_name,
        last_name: last_name,
        mobile: mobile ? mobile : getMobile(),
        mobile_code,
        mobile_value
      };
      if (getConfig().flags.hasYocoPayment) {
        if (mobile) {
          this.props.dispatch(updateProfile(profile, true));
        }
        Basket.createYocoOrder('yoco');
      } else {
        if (mobile) {
          this.props.dispatch(updateProfile(profile, true));
          forwardTo('/checkout');

        } else {
          forwardTo('/checkout');
        }
      }
    }
  }
  handleGuestSave = () => {
    const { dispatch } = this.props;
    let guestFormErrors = validateForm(this.guestValidationConfig, this.state);
    this.setState({ guestFormErrors });

    if (Object.keys(guestFormErrors).length === 0) {
      const {
        guest_email,
        guest_first_name,
        guest_mobile,
        guest_accepted_terms_and_conditions,
        guest_mobile_code,
        guest_mobile_value,
      } = this.state;

      const guestUserData = {
        guest_email,
        guest_first_name,
        guest_mobile,
        guest_accepted_terms_and_conditions,
        guest_mobile_code,
        guest_mobile_value,
      };
      dispatch(setGuestUser(guestUserData));
      forwardTo('/create-account');
    }
  };
  setGuestMobileCode = (guest_mobile_code) => {
    this.setState({ guest_mobile_code, guest_mobile: `${guest_mobile_code}${this.state.guest_mobile_value}` });
    Basket.setMobile(`${guest_mobile_code}${this.state.guest_mobile_value}`);
  };
  setGuestMobileValue = (guest_mobile_value) => {
    this.setState({ guest_mobile_value, guest_mobile: `${this.state.guest_mobile_code}${guest_mobile_value}` });
    Basket.setMobile(`${this.state.guest_mobile_code}${guest_mobile_value}`);
  };
  setMobileCode = (mobile_code) => {
    this.setState({ mobile_code, mobile: `${mobile_code}${this.state.mobile_value}` });
    Basket.setMobile(`${mobile_code}${this.state.mobile_value}`);
  };
  setMobileValue = (mobile_value) => {
    this.setState({ mobile_value, mobile: `${this.state.mobile_code}${mobile_value}` });
    Basket.setMobile(`${this.state.mobile_code}${mobile_value}`);
  };
  render() {
    const { __, auth } = this.props;
    const {
      first_name,
      last_name,
      mobile,
      formErrors,
      guest_first_name,
      guest_mobile,
      guest_email,
      guest_accepted_terms_and_conditions,
      guestFormErrors,
      mobile_code,
      mobile_value,
      guest_mobile_code,
      guest_mobile_value
    } = this.state;

    return (
      <Loading transparent>
        <Layout color='transparent' headerTitle="Contact Details" hideSecondToolbar={true}>
          <div className="absolute-content flex-row-wrapper">
            <div className="scrollable-y checkout">
              <Title className="okx-font-secondary">{__('Contact Details')}</Title>
              <NormalText>
                {__(
                  'To complete your order, please provide a few details in case we need to contact you',
                )}
              </NormalText>
              <Spacer size={2} />
              {this.props.auth && this.props.auth.loggedIn ? (
                <IonList>
                  <div className='input-field-container'>
                    <NormalText>{__('First Name')}</NormalText>
                    <IonItem lines='none' className='input-field-wrapper'>
                      <IonInput
                        onIonInput={(e) => this.handleInput('first_name', e.target.value)}
                        clearInput
                        required={true}
                        type="text"
                        pattern="text"
                        inputmode="text"
                        value={first_name}
                      ></IonInput>
                      {formErrors.first_name ? (
                        <FieldError className="field-error" value={__(formErrors.first_name)} />
                      ) : null}
                    </IonItem>
                  </div>
                  <div className='input-field-container'>
                    <NormalText>{__('Last Name')}</NormalText>
                    <IonItem lines='none' className='input-field-wrapper'>
                      <IonInput
                        onIonInput={(e) => this.handleInput('last_name', e.target.value)}
                        clearInput
                        required={true}
                        type="text"
                        pattern="text"
                        inputmode="text"
                        value={last_name}
                      ></IonInput>
                    </IonItem>
                  </div>

                  {getConfig().flags.hasMobileCountryCode ? (
                    <div className="input-field-container">
                      <NormalText>{__('Mobile Number')}</NormalText>
                      <MobileInput mobile_code={mobile_code} mobile_value={mobile_value} setMobileCode={this.setMobileCode} setMobileValue={this.setMobileValue} />
                    </div>
                  ) : (
                    <div className="input-field-container">
                      <NormalText>{__('Mobile number')}</NormalText>
                      <IonItem lines="none" className="input-field-wrapper">
                        <IonInput
                          onIonInput={(e) => this.handleInput('mobile', e.target.value)}
                          clearInput
                          required={true}
                          type="tel"
                          pattern="tel"
                          inputmode="tel"
                          onKeyPress={(e) => {
                            const reqexFormat = mobile ? '^[0-9]+$' : '^[+0-9]+$';
                            let regex = new RegExp(reqexFormat);
                            if (regex.test(e.key)) {
                              return true;
                            }
                            e.preventDefault();
                            return false;
                          }}
                          value={mobile}
                        ></IonInput>
                      </IonItem>
                    </div>
                  )}
                  {formErrors.mobile ? <FieldError className="field-error" value={__(formErrors.mobile)} /> : null}
                </IonList>
              ) : (
                <IonList>
                  <div className='input-field-container'>
                    <NormalText>{__('First Name')}</NormalText>
                    <IonItem lines='none' className='input-field-wrapper'>
                      <IonInput
                        onIonInput={(e) => this.handleInput('guest_first_name', e.target.value)}
                        clearInput
                        required={true}
                        type="text"
                        pattern="text"
                        inputmode="text"
                        value={guest_first_name}
                        placeholder={__('Enter your first name')}
                      ></IonInput>
                      {guestFormErrors.guest_first_name ? (
                        <FieldError
                          className="field-error"
                          value={__(guestFormErrors.guest_first_name)}
                        />
                      ) : null}
                    </IonItem>
                  </div>
                  <div className='input-field-container'>
                    <NormalText>{__('Email')}</NormalText>
                    <IonItem lines='none' className='input-field-wrapper'>
                      <IonInput
                        onIonInput={(e) => this.handleInput('guest_email', e.target.value.trim())}
                        clearInput
                        required={true}
                        type="text"
                        pattern="text"
                        inputmode="text"
                        value={guest_email}
                        placeholder={__('Enter address')}
                      ></IonInput>
                      {guestFormErrors.guest_email ? (
                        <FieldError className="field-error" value={__(guestFormErrors.guest_email)} />
                      ) : null}
                    </IonItem>
                  </div>
                  {getConfig().flags.hasMobileCountryCode ? (
                    <div className="input-field-container">
                      <MobileInput
                        mobile_code={guest_mobile_code}
                        mobile_value={guest_mobile_value}
                        setMobileCode={this.setGuestMobileCode}
                        setMobileValue={this.setGuestMobileValue}
                      />
                    </div>
                  ) : (
                    <div className="input-field-container">
                      <NormalText>{__('Mobile number')}</NormalText>
                      <IonItem lines="none" className="input-field-wrapper">
                        <IonInput
                          onIonInput={(e) => this.handleInput('guest_mobile', e.target.value)}
                          clearInput
                          required={true}
                          type="tel"
                          pattern="tel"
                          inputmode="tel"
                          onKeyPress={(e) => {
                            const reqexFormat = guest_mobile ? '^[0-9]+$' : '^[+0-9]+$';
                            let regex = new RegExp(reqexFormat);
                            if (regex.test(e.key)) {
                              return true;
                            }
                            e.preventDefault();
                            return false;
                          }}
                          value={guest_mobile}
                        ></IonInput>
                      </IonItem>
                    </div>
                  )}
                  {guestFormErrors.guest_mobile || guestFormErrors.guest_mobile_value ? (
                    <FieldError className="field-error" value={__(guestFormErrors.guest_mobile || guestFormErrors.guest_mobile_value)} />
                  ) : null}
                  <Spacer size={1} />
                  <div lines='none'>
                    <div tabIndex="-1"></div>
                    <div className="toggle">

                      <IonLabel>
                        <StrongText style={{ display: 'block', marginBottom: '10px' }} className='roboto-slab'>{__('Terms & Conditions')}</StrongText>
                        <div className='register-terms-wrapper' >
                          <IonCheckbox
                            color='primary'
                            checked={guest_accepted_terms_and_conditions}
                            onIonChange={(e) =>
                              this.handleInput('guest_accepted_terms_and_conditions', e.detail.checked)
                            } />
                          <IonLabel className="ion-text-wrap">
                            <NormalText color="primary">{__('By signing up you agree to') + ' '} <span className='pointer underlined secondary-color' onClick={() => forwardTo('/terms')}>{__('our terms and conditions')}</span> {__('and')} <span className='secondary-color  pointer underlined' onClick={() => forwardTo('/privacy')}>{__('privacy policy')}</span></NormalText>
                          </IonLabel>
                        </div>
                      </IonLabel>
                      <FieldError
                        className="field-error"
                        value={__(guestFormErrors.guest_accepted_terms_and_conditions)}
                      />                     </div>
                  </div>

                  {/* <IonItem>
                    <div tabIndex="-1"></div>
                    <IonToggle
                      // style={{ paddingRight: '16px' }}
                      color="primary"
                      slot="end"
                      checked={guest_accepted_terms_and_conditions}
                      onIonChange={(e) =>
                        this.handleInput('guest_accepted_terms_and_conditions', e.detail.checked)
                      }
                    />
                    <div className="toggle ">
                      <IonLabel>
                        <h2>{__('Accept T&Cs')}</h2>
                        <IonLabel className="ion-text-wrap">
                          <SmallText>
                            {__('By signing up you agree to our')}{' '}
                            <span className="link underlined" onClick={this.navToTerms}>
                              {__('terms and conditions of service')}
                            </span>{' '}
                            {__('and')}{' '}
                            <span className="link underlined" onClick={() => forwardTo('/privacy')}>
                              {__('privacy policy')}
                            </span>
                          </SmallText>
                        </IonLabel>
                      </IonLabel>
                      <FieldError
                        className="field-error"
                        value={__(guestFormErrors.guest_accepted_terms_and_conditions)}
                      />
                    </div>
                  </IonItem> */}
                </IonList>
              )}
            </div>
            <div className="flex-min">
              <IonButton
                onClick={
                  this.props.auth && this.props.auth.loggedIn
                    ? this.handleSave
                    : this.handleGuestSave
                }
                color='primary'
                expand="block"
                className="uppercase okx-font-secondary"
              >
                {__('Checkout')}
              </IonButton>
            </div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, profile, guestUserData } = state.profile;
  const { cards } = state.orders;
  return {
    auth,
    profile,
    cards,
    guestUserData,
  };
};

export default connect(stateToProps)(withTranslation(ContactDetails));
